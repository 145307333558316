<template>
  <b-form v-if="Object.keys(f).length > 0" id="restDetForm" @submit.stop.prevent>
    <h4>{{ $t('res.ti') }}</h4>
    <b-form-group :label="$t('addr.locnl')" label-for="accRestName">
      <b-input type="text" id="accRestName" readonly :value="f.name" />
    </b-form-group>
    <b-form-group :label="$t('res.fpel')" label-for="accRestEmail" v-if="f.publicEmail">
      <b-input type="email" id="accRestEmail" readonly :value="f.publicEmail" />
    </b-form-group>
    <b-form-group :label="$t('res.fptl')" label-for="accRestTel" v-if="f.publicPhone">
      <b-input type="tel" id="accRestTel" readonly :value="f.publicPhone" />
    </b-form-group>
    <b-row>
      <b-form-group :label="$t('addr.lstl')" label-for="accRestStreet" class="col-8">
        <b-input type="text" id="accRestStreet" readonly :value="f.street" />
      </b-form-group>
      <b-form-group :label="$t('addr.lnol')" label-for="accRestAddrNo" class="col-4">
        <b-input type="text" id="accRestAddrNo" readonly :value="f.number" />
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group :label="$t('addr.lctl')" label-for="accRestCity" class="col-8">
        <b-input type="text" id="accRestCity" readonly :value="f.city" />
      </b-form-group>
      <b-form-group :label="$t('addr.lzpl')" label-for="accRestZip" class="col-4">
        <b-input type="text" id="accRestZip" readonly :value="f.zip" />
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group :label="$t('addr.lcnl')" label-for="accRestCountry" class="col-6">
        <b-form-select id="accRestCountry" disabled :value="f.countryId" :options="optCountries" />
      </b-form-group>
      <b-form-group :label="$t('addr.lsal')" label-for="accRestState" class="col-6" v-if="f.stateId && f.stateId != 0">
        <b-form-select id="accRestState" disabled :value="f.stateId" :options="optStates" />
      </b-form-group>
    </b-row>

    <h4 class="pt-3">
      {{ $t('res.toh') }}
    </h4>
    <b-row class="py-0" v-if="f.locationTimezone!=$store.state.settings.timezone">
      <b-col>
        <p class="mb-2 small text-success">{{ $t('res.ftw') }}</p>
      </b-col>
    </b-row>
    <b-row class="py-0">
      <b-col cols="6" md="7">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.mon')" label-for="accRestMonStart" label-cols-md="5" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestMonStart" readonly :value="d(f.whMoS,0)" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="5">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.to')" label-for="accRestMonStop" label-cols-md="2" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestMonStop" readonly :value="d(f.whMoE,1)" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="py-0">
      <b-col cols="6" md="7">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.tue')" label-for="accRestTueStart" label-cols-md="5" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestTueStart" readonly :value="d(f.whTuS,0)" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="5">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.to')" label-for="accRestTueStop" label-cols-md="2" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestTueStop" readonly :value="d(f.whTuE,1)" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="py-0">
      <b-col cols="6" md="7">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.wed')" label-for="accRestWedStart" label-cols-md="5" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestWedStart" readonly :value="d(f.whWeS,0)" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="5">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.to')" label-for="accRestWedStop" label-cols-md="2" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestWedStop" readonly :value="d(f.whWeE,1)" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="py-0">
      <b-col cols="6" md="7">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.thu')" label-for="accRestThuStart" label-cols-md="5" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestThuStart" readonly :value="d(f.whThS,0)" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="5">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.to')" label-for="accRestThuStop" label-cols-md="2" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestThuStop" readonly :value="d(f.whThE,1)" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="py-0">
      <b-col cols="6" md="7">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.fri')" label-for="accRestFriStart" label-cols-md="5" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestFriStart" readonly :value="d(f.whFrS,0)" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="5">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.to')" label-for="accRestFriStop" label-cols-md="2" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input ctype="text" id="accRestFriStop" readonly :value="d(f.whFrE,1)" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="py-0">
      <b-col cols="6" md="7">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.sat')" label-for="accRestSatStart" label-cols-md="5" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestSatStart" readonly :value="d(f.whSaS,0)" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="5">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.to')" label-for="accRestSatStop" label-cols-md="2" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestSatStop" readonly :value="d(f.whSaE,1)" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="py-0">
      <b-col cols="6" md="7">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.sun')" label-for="accRestSunStart" label-cols-md="5" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestSunStart" readonly :value="d(f.whSuS,0)" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="5">
        <b-form-group class="mb-1 align-items-center" :label="$t('res.to')" label-for="accRestSunStop" label-cols-md="2" label-class="py-0">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-clock />
              </b-input-group-text>
            </template>
            <b-input type="text" id="accRestSunStop" readonly :value="d(f.whSuE,1)" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form class="pt-3 float-right" @submit.stop.prevent>
      <slot name="buttons" />
    </b-form>
  </b-form>
</template>

<script>
import { BIconClock } from 'bootstrap-vue';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import cntryList from '@/components/data/Countries';
import stateList from '@/components/data/States';

export default {
  computed: {
    optCountries() {
      return cntryList.map((v,idx) => { return {value: idx+1, text: this.$t('cntry.'+v)}; }, {});
    },
    optStates() {
      return stateList.map((v,idx) => { return {value: idx+1, text: v.t}; }, {}); // for the moment no translation
    },
    f() {
      return this.item ?? {};
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
  },
  methods: {
    d(v,isE) {
      if (v == null) return ' - ';
      let d = zonedTimeToUtc('2020-01-03 ' + v, this.f.locationTimezone);
      if (isE === 1 && v == '00:00') d.setDate(d.getDate()+1);
      let dTz = utcToZonedTime(d, this.tz);
      let s = this.$d(dTz, 't');
      if (dTz.getDate() > 3) {
        s += ' (+' + (-3 + dTz.getDate()) + this.$t('dt.d') + ')';
      } else if (dTz.getDate() < 3) {
        s += ' (' + (-3 + dTz.getDate()) + this.$t('dt.d') + ')';
      }
      return s;
    },
  },
  props: {
    item: {
      type: Object,
      default: null,
      // required: true, // required disabled as existence check w/ null is complicated: https://stackoverflow.com/questions/49647409
    },
  },
  components: {
    BIconClock,
  },
}
</script>
