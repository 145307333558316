<template>
  <RestaurantContent :item="item" v-if="isUser">
    <template #buttons>
      <b-button type="button" variant="primary" id="restBack"
          @click="onBack()">
        <Icon type="b" /> {{ $t('btn.b') }}
      </b-button>
    </template>
  </RestaurantContent>
</template>

<script>
import Icon from '@/components/Icon';
import RestaurantContent from '@/components/shared/RestaurantContent';
import { REST } from '@/components/RestCall';
import Query from  '@/components/RestQuery';

export default {
  data() {
    return {
      isForReservation: true,
      item: null,
    }
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    details() {
      return this.isForReservation ? this.$store.state.oResDetails : this.$store.state.oTraDetails;
    },
    restaurantUuid() {
      return this.isForReservation ? this.$store.state.oResDetails.customerUuid4 : this.$store.state.oTraDetails.receiverUuid4;
    },
  },
  created() {
    this.isForReservation = this.$route.meta.isReservation ?? false;
    if (this.details === null) {
      this.$router.go(-1); // return from where we came from
      return;
    }
    let qArray = [['uuid4', 'e', this.restaurantUuid]];
    let qObj = Query.setFilter({ p: '1' }, qArray);
    REST.get('/restaurants', { params: qObj }
        ).then(resp => {
          this.item = resp.d.data[0];
        }).catch(e => {
          this.$emit('warn', e.message);
          this.$router.go(-1); // auto-return from where we came from
        })
  },
  methods: {
    onBack() {
      this.item = null;
      this.$router.go(-1); // return from where we came from
    },
  },
  components: {
    RestaurantContent,
    Icon,
  },
}
</script>
